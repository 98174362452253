<template>
  <AdminMain />
</template>

<script>
import AdminMain from '@/components/admin/AdminMain';

export default {
  name: 'Admin',
  components: {
    AdminMain
  }
}
</script>

<style>

</style>