<template>
  <section class="block paginate_block general">
    <CommonTitle :options="options_title" />
    <CommonTable :options="options_table" />
  </section>
</template>

<script>
import Axios from 'axios';
import CommonTable from '../common/CommonTable';
import CommonTitle from '../common/CommonTitle';

export default {
  name: 'UserMain',
  data() {
    return {
      options_title: {
        name: 'Администраторы',
        id: 'admin',
        actions: '',
      },
      options_table: {
        id: 'admin',
        request: 'user?role=2',
        actions: 'edit-remove',
        ths: [
          { id: 'login', name: 'Логин', width: '30%', value: '', sort: true, sort_data: '', search: true },
          { id: 'email', name: 'Почта', width: '30%', value: '', sort: true, sort_data: '', search: true },
          { id: 'phone', name: 'Номер телефона', width: '30%', value: '', sort: true, sort_data: '', search: true },
          { id: '', name: '', width: '10%', value: '', sort: false, search: false },
        ],
        table: {
          variables: ['login', 'email', 'phone'],
          page: 1,
          limit: 15,
          total: 0,
          data: []
        }
      }
    }
  },
  mounted() {
    this.$bus.$on('createAdmin', (result) => {
      this.options_table.table.data.unshift(result.data);
    });
    this.$bus.$on('editAdmin', (result) => {
      const admin = this.options_table.table.data.find(el => el._id === result.data._id);
      if (admin) {
        for (let i in result.data) {
          admin[i] = result.data[i];
        }
      }
    });
    this.$bus.$on('removeAdmin', (result) => {
      this.options_table.table.data = this.options_table.table.data.filter(el => el._id !== result.data);
    });

    Axios
      .get(`/api/user`, {
        headers: {
          token: this.$store.getters.token
        },
        params: {
          role: 2,
          page: 0,
        }
      })
      .then(res => {
        this.options_table.table = {
          data: res.data.data,
          variables: ['login', 'email', 'phone'],
          page: 1,
          limit: 15,
          total: res.data.total
        }
      })
      .catch(err => {
        console.error(err.response);
      })
  },
  components: {
    CommonTitle,
    CommonTable
  }
}
</script>

<style scoped>
.block {
  flex-grow: 0;
  width: 60%;
}
</style>